import { createFetchingSWR } from './_factory'

import { libraryService } from '~/services'

export const useLibraryList = createFetchingSWR({
  key: 'library-list',
  fetcher: libraryService.getList,
  withAuth: 'when-available'
})

export function useUserLibrary() {
  const swr = useLibraryList({ author: '$user._id' })
  const appliedSWR = {
    ...swr,
    data: swr.data?.[0] ?? null
  }

  return appliedSWR
}

export const useLibrary = createFetchingSWR({
  key: 'library',
  fetcher: libraryService.getByIdOrBasePath,
  withAuth: 'when-available'
})
